import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { TitleDetailsFragmentDoc } from '../fragments/TitleDetails.fragment';
export type GetNodeTitleDetailsQueryVariables = Types.Exact<{
	entityId: Types.Scalars['ID']['input'];
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	episodeMaxLimit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
	platform?: Types.Platform;
	allowSponsoredRecommendations?: Types.InputMaybe<Types.SponsoredRecommendationsInput>;
	format?: Types.InputMaybe<Types.ImageFormat>;
	backdropProfile?: Types.InputMaybe<Types.BackdropProfile>;
	streamingChartsFilter?: Types.InputMaybe<Types.StreamingChartsFilter>;
}>;

export type GetNodeTitleDetailsQuery = {
	__typename?: 'Query';
	node?:
		| { __typename: 'Article'; id: string }
		| { __typename: 'Author'; id: string }
		| { __typename: 'Bundle'; id: string }
		| { __typename: 'Device'; id: string }
		| { __typename: 'Episode'; id: string }
		| { __typename: 'GenericTitleList'; id: string }
		| { __typename: 'Genre'; id: string }
		| {
				__typename: 'Movie';
				maxOfferUpdatedAt?: any | null;
				objectType: Types.ObjectType;
				objectId: number;
				offerCount: number;
				permanentAudiences: Array<string>;
				id: string;
				disneyOffersCount: number;
				starOffersCount: number;
				uniqueOfferCount: number;
				plexPlayerOffers: Array<{
					__typename?: 'Offer';
					id: string;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				}>;
				justwatchTVOffers: Array<{
					__typename?: 'Offer';
					id: string;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				}>;
				appleOffers: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				offers: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				watchNowOffer?: {
					__typename?: 'Offer';
					id: string;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				} | null;
				promotedBundles: Array<{ __typename?: 'PromotedBundleEdge'; promotionUrl: string }>;
				availableTo: Array<{
					__typename?: 'AvailableTo';
					availableCountDown: number;
					availableToDate: any;
					package: { __typename?: 'Package'; id: string; shortName: string };
				}>;
				fallBackClips: {
					__typename?: 'MovieContent';
					clips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					videobusterClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					dailymotionClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
				};
				content: {
					__typename?: 'MovieContent';
					originalTitle: string;
					ageCertification?: string | null;
					productionCountries: Array<any>;
					fullPath: string;
					posterUrl?: string | null;
					runtime?: number | null;
					isReleased: boolean;
					shortDescription?: string | null;
					title: string;
					originalReleaseYear?: number | null;
					originalReleaseDate?: any | null;
					fullPosterUrl?: string | null;
					subgenres: Array<{
						__typename?: 'GenericTitleList';
						content: {
							__typename?: 'GenericTitleListContent';
							shortName?: string | null;
							name: string;
							url?: { __typename?: 'Url'; fullPath: string } | null;
						};
					}>;
					credits: Array<{
						__typename?: 'Credit';
						role: Types.CreditRole;
						name: string;
						characterName?: string | null;
						personId: number;
					}>;
					interactions: {
						__typename?: 'InteractionAttributes';
						dislikelistAdditions: number;
						likelistAdditions: number;
						votesNumber: number;
					};
					backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					fullBackdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					clips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					videobusterClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					dailymotionClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
					scoring: {
						__typename?: 'Scoring';
						imdbScore?: number | null;
						imdbVotes?: number | null;
						tmdbPopularity?: number | null;
						tmdbScore?: number | null;
						jwRating?: number | null;
						tomatoMeter?: number | null;
						certifiedFresh?: boolean | null;
					};
					upcomingReleases: Array<{
						__typename?: 'UpcomingRelease';
						releaseCountDown?: number | null;
						releaseDate?: any | null;
						releaseType: Types.ReleaseType;
						label: Types.UpcomingReleaseLabel;
						package?: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							shortName: string;
							clearName: string;
							monetizationTypes: Array<Types.MonetizationType>;
							icon: string;
							hasRectangularIcon: boolean;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								retailPrice?: string | null;
								durationDays?: number | null;
								presentationType?: Types.PresentationType | null;
								isTrial?: boolean | null;
								retailPriceValue?: number | null;
								currency?: string | null;
							}>;
						} | null;
					}>;
					genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
				};
				popularityRank: {
					__typename?: 'PopularityRank';
					rank: number;
					trend: Types.ModuleContentTitleTrend;
					trendDifference: number;
				};
				streamingCharts: {
					__typename?: 'StreamingChartsConnection';
					edges?: Array<{
						__typename?: 'StreamingChartsTitlesEdge';
						streamingChartInfo?: {
							__typename?: 'StreamingChartInfo';
							rank: number;
							trend: Types.ModuleContentTitleTrend;
							trendDifference: number;
							updatedAt?: any | null;
							daysInTop10: number;
							daysInTop100: number;
							daysInTop1000: number;
							daysInTop3: number;
							topRank: number;
						} | null;
					}> | null;
				};
				likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				customlistEntries: Array<{
					__typename?: 'TitleListEntry';
					createdAt: any;
					genericTitleList: { __typename?: 'GenericTitleList'; id: string };
				}>;
				similarTitlesV2: {
					__typename?: 'SimilarTitlesConnection';
					sponsoredAd?: {
						__typename?: 'SponsoredRecommendationAd';
						bidId: string;
						holdoutGroup: boolean;
						campaign?: {
							__typename?: 'SponsoredRecommendationCampaign';
							name: string;
							countdownTimer?: any | null;
							creativeType?: Types.CreativeType | null;
							disclaimerText?: string | null;
							hideDetailPageButton: boolean;
							hideImdbScore?: boolean | null;
							hideJwScore?: boolean | null;
							hideRatings: boolean;
							hideContent?: boolean | null;
							posterOverride?: string | null;
							promotionalImageUrl?: string | null;
							promotionalTitle?: string | null;
							promotionalText?: string | null;
							promotionalProviderLogo?: string | null;
							promotionalProviderWideLogo?: string | null;
							watchNowLabel?: string | null;
							backgroundImages?: Array<{
								__typename?: 'BackgroundImage';
								imageURL: string;
								size: Types.BackgroundImageSize;
							} | null> | null;
							externalTrackers: Array<{
								__typename?: 'ExternalTracker';
								type: Types.ExternalTrackerType;
								data: string;
							}>;
							promotionalVideo?: { __typename?: 'PromotionalVideo'; url: string } | null;
							watchNowOffer: {
								__typename?: 'Offer';
								id: string;
								standardWebURL?: string | null;
								streamUrl?: string | null;
								retailPrice?: string | null;
								retailPriceValue?: number | null;
								lastChangeRetailPriceValue?: number | null;
								currency?: string | null;
								presentationType: Types.PresentationType;
								monetizationType: Types.MonetizationType;
								availableTo?: any | null;
								package: {
									__typename?: 'Package';
									id: string;
									icon: string;
									packageId: number;
									clearName: string;
									shortName: string;
									technicalName: string;
									iconWide: string;
									hasRectangularIcon: boolean;
								};
							};
							nodeOverrides: Array<{
								__typename?: 'NodeOverride';
								nodeId: string;
								promotionalImageUrl?: string | null;
								watchNowOffer?: { __typename?: 'Offer'; standardWebURL?: string | null } | null;
							}>;
							node:
								| { __typename: 'Article'; nodeId: string }
								| { __typename: 'Author'; nodeId: string }
								| { __typename: 'Bundle'; nodeId: string }
								| { __typename: 'Device'; nodeId: string }
								| { __typename: 'Episode'; nodeId: string }
								| {
										__typename: 'GenericTitleList';
										id: string;
										type: Types.GenericTitleListType;
										nodeId: string;
										followedlistEntry?: {
											__typename?: 'FollowedListEntry';
											createdAt: any;
											name: string;
										} | null;
										content: {
											__typename?: 'GenericTitleListContent';
											name: string;
											visibility: Types.GenericTitleListVisibility;
										};
										titles: {
											__typename?: 'GenericTitleListConnection';
											totalCount: number;
											edges?: Array<{
												__typename?: 'GenericTitleListEdge';
												cursor: string;
												node:
													| {
															__typename?: 'Movie';
															id: string;
															objectId: number;
															objectType: Types.ObjectType;
															content: {
																__typename?: 'MovieContent';
																fullPath: string;
																posterUrl?: string | null;
																title: string;
																originalReleaseYear?: number | null;
																isReleased: boolean;
																scoring: {
																	__typename?: 'Scoring';
																	imdbVotes?: number | null;
																	imdbScore?: number | null;
																	tomatoMeter?: number | null;
																	certifiedFresh?: boolean | null;
																	jwRating?: number | null;
																};
															};
													  }
													| {
															__typename?: 'Season';
															id: string;
															objectId: number;
															objectType: Types.ObjectType;
															content: {
																__typename?: 'SeasonContent';
																fullPath: string;
																posterUrl?: string | null;
																title: string;
																originalReleaseYear?: number | null;
																isReleased: boolean;
																scoring: {
																	__typename?: 'Scoring';
																	imdbVotes?: number | null;
																	imdbScore?: number | null;
																	tomatoMeter?: number | null;
																	certifiedFresh?: boolean | null;
																	jwRating?: number | null;
																};
															};
													  }
													| {
															__typename?: 'Show';
															id: string;
															objectId: number;
															objectType: Types.ObjectType;
															content: {
																__typename?: 'ShowContent';
																fullPath: string;
																posterUrl?: string | null;
																title: string;
																originalReleaseYear?: number | null;
																isReleased: boolean;
																scoring: {
																	__typename?: 'Scoring';
																	imdbVotes?: number | null;
																	imdbScore?: number | null;
																	tomatoMeter?: number | null;
																	certifiedFresh?: boolean | null;
																	jwRating?: number | null;
																};
															};
													  };
											}> | null;
										};
								  }
								| { __typename: 'Genre'; nodeId: string }
								| {
										__typename: 'Movie';
										objectId: number;
										objectType: Types.ObjectType;
										nodeId: string;
										content: {
											__typename?: 'MovieContent';
											fullPath: string;
											posterUrl?: string | null;
											title: string;
											originalReleaseYear?: number | null;
											isReleased: boolean;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												jwRating?: number | null;
											};
											genres: Array<{
												__typename?: 'Genre';
												shortName: string;
												translation: string;
											}>;
											externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										offers: Array<{
											__typename?: 'Offer';
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											id: string;
											package: { __typename?: 'Package'; id: string; packageId: number };
										}>;
										watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
								  }
								| { __typename: 'MultiStepSportEvent'; nodeId: string }
								| { __typename: 'Offer'; nodeId: string }
								| { __typename: 'Package'; nodeId: string }
								| { __typename: 'Page'; nodeId: string }
								| { __typename: 'Person'; nodeId: string }
								| {
										__typename: 'Season';
										objectId: number;
										objectType: Types.ObjectType;
										nodeId: string;
										content: {
											__typename?: 'SeasonContent';
											fullPath: string;
											posterUrl?: string | null;
											title: string;
											originalReleaseYear?: number | null;
											isReleased: boolean;
											seasonNumber: number;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												jwRating?: number | null;
											};
											genres: Array<{
												__typename?: 'Genre';
												shortName: string;
												translation: string;
											}>;
											externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										offers: Array<{
											__typename?: 'Offer';
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											id: string;
											package: { __typename?: 'Package'; id: string; packageId: number };
										}>;
										show: {
											__typename: 'Show';
											id: string;
											objectId: number;
											objectType: Types.ObjectType;
											content: { __typename?: 'ShowContent'; originalTitle: string };
											watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										};
								  }
								| {
										__typename: 'Show';
										objectId: number;
										objectType: Types.ObjectType;
										nodeId: string;
										content: {
											__typename?: 'ShowContent';
											fullPath: string;
											posterUrl?: string | null;
											title: string;
											originalReleaseYear?: number | null;
											isReleased: boolean;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												jwRating?: number | null;
											};
											genres: Array<{
												__typename?: 'Genre';
												shortName: string;
												translation: string;
											}>;
											externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										offers: Array<{
											__typename?: 'Offer';
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											id: string;
											package: { __typename?: 'Package'; id: string; packageId: number };
										}>;
										watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number };
								  }
								| { __typename: 'SingleStepSportEvent'; nodeId: string }
								| { __typename: 'SportCompetitionV2'; nodeId: string }
								| { __typename: 'SportCompetitorV2'; nodeId: string }
								| { __typename: 'Url'; nodeId: string };
						} | null;
					} | null;
				};
				seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
		  }
		| { __typename: 'MultiStepSportEvent'; id: string }
		| { __typename: 'Offer'; id: string }
		| { __typename: 'Package'; id: string }
		| { __typename: 'Page'; id: string }
		| { __typename: 'Person'; id: string }
		| {
				__typename: 'Season';
				maxOfferUpdatedAt?: any | null;
				objectType: Types.ObjectType;
				objectId: number;
				offerCount: number;
				totalEpisodeCount: number;
				id: string;
				disneyOffersCount: number;
				starOffersCount: number;
				uniqueOfferCount: number;
				plexPlayerOffers: Array<{
					__typename?: 'Offer';
					id: string;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				}>;
				justwatchTVOffers: Array<{
					__typename?: 'Offer';
					id: string;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				}>;
				appleOffers: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				offers: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				watchNowOffer?: {
					__typename?: 'Offer';
					id: string;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				} | null;
				promotedBundles: Array<{ __typename?: 'PromotedBundleEdge'; promotionUrl: string }>;
				availableTo: Array<{
					__typename?: 'AvailableTo';
					availableCountDown: number;
					availableToDate: any;
					package: { __typename?: 'Package'; id: string; shortName: string };
				}>;
				fallBackClips: {
					__typename?: 'SeasonContent';
					clips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					videobusterClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					dailymotionClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
				};
				content: {
					__typename?: 'SeasonContent';
					seasonNumber: number;
					fullPath: string;
					posterUrl?: string | null;
					runtime?: number | null;
					isReleased: boolean;
					shortDescription?: string | null;
					title: string;
					originalReleaseYear?: number | null;
					originalReleaseDate?: any | null;
					fullPosterUrl?: string | null;
					subgenres: Array<{
						__typename?: 'GenericTitleList';
						content: {
							__typename?: 'GenericTitleListContent';
							shortName?: string | null;
							name: string;
							url?: { __typename?: 'Url'; fullPath: string } | null;
						};
					}>;
					interactions: {
						__typename?: 'InteractionAttributes';
						dislikelistAdditions: number;
						likelistAdditions: number;
						votesNumber: number;
					};
					backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					fullBackdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					clips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					videobusterClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					dailymotionClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
					scoring: {
						__typename?: 'Scoring';
						imdbScore?: number | null;
						imdbVotes?: number | null;
						tmdbPopularity?: number | null;
						tmdbScore?: number | null;
						jwRating?: number | null;
						tomatoMeter?: number | null;
						certifiedFresh?: boolean | null;
					};
					upcomingReleases: Array<{
						__typename?: 'UpcomingRelease';
						releaseCountDown?: number | null;
						releaseDate?: any | null;
						releaseType: Types.ReleaseType;
						label: Types.UpcomingReleaseLabel;
						package?: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							shortName: string;
							clearName: string;
							monetizationTypes: Array<Types.MonetizationType>;
							icon: string;
							hasRectangularIcon: boolean;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								retailPrice?: string | null;
								durationDays?: number | null;
								presentationType?: Types.PresentationType | null;
								isTrial?: boolean | null;
								retailPriceValue?: number | null;
								currency?: string | null;
							}>;
						} | null;
					}>;
					genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
				};
				popularityRank: {
					__typename?: 'PopularityRank';
					rank: number;
					trend: Types.ModuleContentTitleTrend;
					trendDifference: number;
				};
				streamingCharts: {
					__typename?: 'StreamingChartsConnection';
					edges?: Array<{
						__typename?: 'StreamingChartsTitlesEdge';
						streamingChartInfo?: {
							__typename?: 'StreamingChartInfo';
							rank: number;
							trend: Types.ModuleContentTitleTrend;
							trendDifference: number;
							updatedAt?: any | null;
							daysInTop10: number;
							daysInTop100: number;
							daysInTop1000: number;
							daysInTop3: number;
							topRank: number;
						} | null;
					}> | null;
				};
				likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				episodes: Array<{
					__typename?: 'Episode';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					uniqueOfferCount: number;
					seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					flatrate: Array<{
						__typename?: 'Offer';
						id: string;
						package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
					}>;
					buy: Array<{
						__typename?: 'Offer';
						id: string;
						package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
					}>;
					rent: Array<{
						__typename?: 'Offer';
						id: string;
						package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
					}>;
					free: Array<{
						__typename?: 'Offer';
						id: string;
						package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
					}>;
					fast: Array<{
						__typename?: 'Offer';
						id: string;
						package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
					}>;
					content: {
						__typename?: 'EpisodeContent';
						title: string;
						shortDescription?: string | null;
						episodeNumber: number;
						seasonNumber: number;
						isReleased: boolean;
						runtime?: number | null;
						upcomingReleases: Array<{
							__typename?: 'UpcomingRelease';
							releaseDate?: any | null;
							label: Types.UpcomingReleaseLabel;
							package?: { __typename?: 'Package'; id: string; packageId: number } | null;
						}>;
					};
				}>;
				show: {
					__typename: 'Show';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					totalSeasonCount: number;
					customlistEntries: Array<{
						__typename?: 'TitleListEntry';
						createdAt: any;
						genericTitleList: { __typename?: 'GenericTitleList'; id: string };
					}>;
					tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					fallBackClips: {
						__typename?: 'ShowContent';
						clips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
							name: string;
						}>;
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
							name: string;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
							name: string;
						}>;
					};
					content: {
						__typename?: 'ShowContent';
						title: string;
						ageCertification?: string | null;
						fullPath: string;
						productionCountries: Array<any>;
						posterUrl?: string | null;
						isReleased: boolean;
						genres: Array<{ __typename?: 'Genre'; shortName: string }>;
						credits: Array<{
							__typename?: 'Credit';
							role: Types.CreditRole;
							name: string;
							characterName?: string | null;
							personId: number;
						}>;
						externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
						upcomingReleases: Array<{
							__typename?: 'UpcomingRelease';
							releaseDate?: any | null;
							releaseType: Types.ReleaseType;
							package?: {
								__typename?: 'Package';
								id: string;
								shortName: string;
								planOffers: Array<{
									__typename?: 'PackagePlanOffer';
									retailPrice?: string | null;
									durationDays?: number | null;
									presentationType?: Types.PresentationType | null;
									isTrial?: boolean | null;
									retailPriceValue?: number | null;
									currency?: string | null;
								}>;
							} | null;
						}>;
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
							name: string;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
							name: string;
						}>;
					};
					seenState: { __typename?: 'ShowSeenState'; progress: number };
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					similarTitlesV2: {
						__typename?: 'SimilarTitlesConnection';
						sponsoredAd?: {
							__typename?: 'SponsoredRecommendationAd';
							bidId: string;
							holdoutGroup: boolean;
							campaign?: {
								__typename?: 'SponsoredRecommendationCampaign';
								name: string;
								countdownTimer?: any | null;
								creativeType?: Types.CreativeType | null;
								disclaimerText?: string | null;
								hideDetailPageButton: boolean;
								hideImdbScore?: boolean | null;
								hideJwScore?: boolean | null;
								hideRatings: boolean;
								hideContent?: boolean | null;
								posterOverride?: string | null;
								promotionalImageUrl?: string | null;
								promotionalTitle?: string | null;
								promotionalText?: string | null;
								promotionalProviderLogo?: string | null;
								promotionalProviderWideLogo?: string | null;
								watchNowLabel?: string | null;
								backgroundImages?: Array<{
									__typename?: 'BackgroundImage';
									imageURL: string;
									size: Types.BackgroundImageSize;
								} | null> | null;
								externalTrackers: Array<{
									__typename?: 'ExternalTracker';
									type: Types.ExternalTrackerType;
									data: string;
								}>;
								promotionalVideo?: { __typename?: 'PromotionalVideo'; url: string } | null;
								watchNowOffer: {
									__typename?: 'Offer';
									id: string;
									standardWebURL?: string | null;
									streamUrl?: string | null;
									retailPrice?: string | null;
									retailPriceValue?: number | null;
									lastChangeRetailPriceValue?: number | null;
									currency?: string | null;
									presentationType: Types.PresentationType;
									monetizationType: Types.MonetizationType;
									availableTo?: any | null;
									package: {
										__typename?: 'Package';
										id: string;
										icon: string;
										packageId: number;
										clearName: string;
										shortName: string;
										technicalName: string;
										iconWide: string;
										hasRectangularIcon: boolean;
									};
								};
								nodeOverrides: Array<{
									__typename?: 'NodeOverride';
									nodeId: string;
									promotionalImageUrl?: string | null;
									watchNowOffer?: { __typename?: 'Offer'; standardWebURL?: string | null } | null;
								}>;
								node:
									| { __typename: 'Article'; nodeId: string }
									| { __typename: 'Author'; nodeId: string }
									| { __typename: 'Bundle'; nodeId: string }
									| { __typename: 'Device'; nodeId: string }
									| { __typename: 'Episode'; nodeId: string }
									| {
											__typename: 'GenericTitleList';
											id: string;
											type: Types.GenericTitleListType;
											nodeId: string;
											followedlistEntry?: {
												__typename?: 'FollowedListEntry';
												createdAt: any;
												name: string;
											} | null;
											content: {
												__typename?: 'GenericTitleListContent';
												name: string;
												visibility: Types.GenericTitleListVisibility;
											};
											titles: {
												__typename?: 'GenericTitleListConnection';
												totalCount: number;
												edges?: Array<{
													__typename?: 'GenericTitleListEdge';
													cursor: string;
													node:
														| {
																__typename?: 'Movie';
																id: string;
																objectId: number;
																objectType: Types.ObjectType;
																content: {
																	__typename?: 'MovieContent';
																	fullPath: string;
																	posterUrl?: string | null;
																	title: string;
																	originalReleaseYear?: number | null;
																	isReleased: boolean;
																	scoring: {
																		__typename?: 'Scoring';
																		imdbVotes?: number | null;
																		imdbScore?: number | null;
																		tomatoMeter?: number | null;
																		certifiedFresh?: boolean | null;
																		jwRating?: number | null;
																	};
																};
														  }
														| {
																__typename?: 'Season';
																id: string;
																objectId: number;
																objectType: Types.ObjectType;
																content: {
																	__typename?: 'SeasonContent';
																	fullPath: string;
																	posterUrl?: string | null;
																	title: string;
																	originalReleaseYear?: number | null;
																	isReleased: boolean;
																	scoring: {
																		__typename?: 'Scoring';
																		imdbVotes?: number | null;
																		imdbScore?: number | null;
																		tomatoMeter?: number | null;
																		certifiedFresh?: boolean | null;
																		jwRating?: number | null;
																	};
																};
														  }
														| {
																__typename?: 'Show';
																id: string;
																objectId: number;
																objectType: Types.ObjectType;
																content: {
																	__typename?: 'ShowContent';
																	fullPath: string;
																	posterUrl?: string | null;
																	title: string;
																	originalReleaseYear?: number | null;
																	isReleased: boolean;
																	scoring: {
																		__typename?: 'Scoring';
																		imdbVotes?: number | null;
																		imdbScore?: number | null;
																		tomatoMeter?: number | null;
																		certifiedFresh?: boolean | null;
																		jwRating?: number | null;
																	};
																};
														  };
												}> | null;
											};
									  }
									| { __typename: 'Genre'; nodeId: string }
									| {
											__typename: 'Movie';
											objectId: number;
											objectType: Types.ObjectType;
											nodeId: string;
											content: {
												__typename?: 'MovieContent';
												fullPath: string;
												posterUrl?: string | null;
												title: string;
												originalReleaseYear?: number | null;
												isReleased: boolean;
												scoring: {
													__typename?: 'Scoring';
													imdbScore?: number | null;
													jwRating?: number | null;
												};
												genres: Array<{
													__typename?: 'Genre';
													shortName: string;
													translation: string;
												}>;
												externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
												backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
											};
											offers: Array<{
												__typename?: 'Offer';
												monetizationType: Types.MonetizationType;
												presentationType: Types.PresentationType;
												id: string;
												package: { __typename?: 'Package'; id: string; packageId: number };
											}>;
											watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									  }
									| { __typename: 'MultiStepSportEvent'; nodeId: string }
									| { __typename: 'Offer'; nodeId: string }
									| { __typename: 'Package'; nodeId: string }
									| { __typename: 'Page'; nodeId: string }
									| { __typename: 'Person'; nodeId: string }
									| {
											__typename: 'Season';
											objectId: number;
											objectType: Types.ObjectType;
											nodeId: string;
											content: {
												__typename?: 'SeasonContent';
												fullPath: string;
												posterUrl?: string | null;
												title: string;
												originalReleaseYear?: number | null;
												isReleased: boolean;
												seasonNumber: number;
												scoring: {
													__typename?: 'Scoring';
													imdbScore?: number | null;
													jwRating?: number | null;
												};
												genres: Array<{
													__typename?: 'Genre';
													shortName: string;
													translation: string;
												}>;
												externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
												backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
											};
											offers: Array<{
												__typename?: 'Offer';
												monetizationType: Types.MonetizationType;
												presentationType: Types.PresentationType;
												id: string;
												package: { __typename?: 'Package'; id: string; packageId: number };
											}>;
											show: {
												__typename: 'Show';
												id: string;
												objectId: number;
												objectType: Types.ObjectType;
												content: { __typename?: 'ShowContent'; originalTitle: string };
												watchlistEntryV2?: {
													__typename?: 'TitleListEntry';
													createdAt: any;
												} | null;
											};
									  }
									| {
											__typename: 'Show';
											objectId: number;
											objectType: Types.ObjectType;
											nodeId: string;
											content: {
												__typename?: 'ShowContent';
												fullPath: string;
												posterUrl?: string | null;
												title: string;
												originalReleaseYear?: number | null;
												isReleased: boolean;
												scoring: {
													__typename?: 'Scoring';
													imdbScore?: number | null;
													jwRating?: number | null;
												};
												genres: Array<{
													__typename?: 'Genre';
													shortName: string;
													translation: string;
												}>;
												externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
												backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
											};
											offers: Array<{
												__typename?: 'Offer';
												monetizationType: Types.MonetizationType;
												presentationType: Types.PresentationType;
												id: string;
												package: { __typename?: 'Package'; id: string; packageId: number };
											}>;
											watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
											seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number };
									  }
									| { __typename: 'SingleStepSportEvent'; nodeId: string }
									| { __typename: 'SportCompetitionV2'; nodeId: string }
									| { __typename: 'SportCompetitorV2'; nodeId: string }
									| { __typename: 'Url'; nodeId: string };
							} | null;
						} | null;
					};
				};
				seenState: { __typename?: 'ShowSeenState'; progress: number };
		  }
		| {
				__typename: 'Show';
				maxOfferUpdatedAt?: any | null;
				objectType: Types.ObjectType;
				objectId: number;
				offerCount: number;
				permanentAudiences: Array<string>;
				totalSeasonCount: number;
				id: string;
				disneyOffersCount: number;
				starOffersCount: number;
				uniqueOfferCount: number;
				plexPlayerOffers: Array<{
					__typename?: 'Offer';
					id: string;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				}>;
				justwatchTVOffers: Array<{
					__typename?: 'Offer';
					id: string;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				}>;
				appleOffers: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				offers: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				watchNowOffer?: {
					__typename?: 'Offer';
					id: string;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					lastChangeRetailPriceValue?: number | null;
					currency?: string | null;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					availableTo?: any | null;
					package: {
						__typename?: 'Package';
						id: string;
						icon: string;
						packageId: number;
						clearName: string;
						shortName: string;
						technicalName: string;
						iconWide: string;
						hasRectangularIcon: boolean;
					};
				} | null;
				promotedBundles: Array<{ __typename?: 'PromotedBundleEdge'; promotionUrl: string }>;
				availableTo: Array<{
					__typename?: 'AvailableTo';
					availableCountDown: number;
					availableToDate: any;
					package: { __typename?: 'Package'; id: string; shortName: string };
				}>;
				fallBackClips: {
					__typename?: 'ShowContent';
					clips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					videobusterClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					dailymotionClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
				};
				content: {
					__typename?: 'ShowContent';
					originalTitle: string;
					ageCertification?: string | null;
					productionCountries: Array<any>;
					fullPath: string;
					posterUrl?: string | null;
					runtime?: number | null;
					isReleased: boolean;
					shortDescription?: string | null;
					title: string;
					originalReleaseYear?: number | null;
					originalReleaseDate?: any | null;
					fullPosterUrl?: string | null;
					subgenres: Array<{
						__typename?: 'GenericTitleList';
						content: {
							__typename?: 'GenericTitleListContent';
							shortName?: string | null;
							name: string;
							url?: { __typename?: 'Url'; fullPath: string } | null;
						};
					}>;
					credits: Array<{
						__typename?: 'Credit';
						role: Types.CreditRole;
						name: string;
						characterName?: string | null;
						personId: number;
					}>;
					interactions: {
						__typename?: 'InteractionAttributes';
						dislikelistAdditions: number;
						likelistAdditions: number;
						votesNumber: number;
					};
					backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					fullBackdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					clips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					videobusterClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					dailymotionClips: Array<{
						__typename?: 'Clip';
						sourceUrl: string;
						externalId: string;
						provider: Types.ClipProvider;
						name: string;
					}>;
					externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
					scoring: {
						__typename?: 'Scoring';
						imdbScore?: number | null;
						imdbVotes?: number | null;
						tmdbPopularity?: number | null;
						tmdbScore?: number | null;
						jwRating?: number | null;
						tomatoMeter?: number | null;
						certifiedFresh?: boolean | null;
					};
					upcomingReleases: Array<{
						__typename?: 'UpcomingRelease';
						releaseCountDown?: number | null;
						releaseDate?: any | null;
						releaseType: Types.ReleaseType;
						label: Types.UpcomingReleaseLabel;
						package?: {
							__typename?: 'Package';
							id: string;
							packageId: number;
							shortName: string;
							clearName: string;
							monetizationTypes: Array<Types.MonetizationType>;
							icon: string;
							hasRectangularIcon: boolean;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								retailPrice?: string | null;
								durationDays?: number | null;
								presentationType?: Types.PresentationType | null;
								isTrial?: boolean | null;
								retailPriceValue?: number | null;
								currency?: string | null;
							}>;
						} | null;
					}>;
					genres: Array<{ __typename?: 'Genre'; shortName: string; translation: string }>;
				};
				popularityRank: {
					__typename?: 'PopularityRank';
					rank: number;
					trend: Types.ModuleContentTitleTrend;
					trendDifference: number;
				};
				streamingCharts: {
					__typename?: 'StreamingChartsConnection';
					edges?: Array<{
						__typename?: 'StreamingChartsTitlesEdge';
						streamingChartInfo?: {
							__typename?: 'StreamingChartInfo';
							rank: number;
							trend: Types.ModuleContentTitleTrend;
							trendDifference: number;
							updatedAt?: any | null;
							daysInTop10: number;
							daysInTop100: number;
							daysInTop1000: number;
							daysInTop3: number;
							topRank: number;
						} | null;
					}> | null;
				};
				likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				customlistEntries: Array<{
					__typename?: 'TitleListEntry';
					createdAt: any;
					genericTitleList: { __typename?: 'GenericTitleList'; id: string };
				}>;
				similarTitlesV2: {
					__typename?: 'SimilarTitlesConnection';
					sponsoredAd?: {
						__typename?: 'SponsoredRecommendationAd';
						bidId: string;
						holdoutGroup: boolean;
						campaign?: {
							__typename?: 'SponsoredRecommendationCampaign';
							name: string;
							countdownTimer?: any | null;
							creativeType?: Types.CreativeType | null;
							disclaimerText?: string | null;
							hideDetailPageButton: boolean;
							hideImdbScore?: boolean | null;
							hideJwScore?: boolean | null;
							hideRatings: boolean;
							hideContent?: boolean | null;
							posterOverride?: string | null;
							promotionalImageUrl?: string | null;
							promotionalTitle?: string | null;
							promotionalText?: string | null;
							promotionalProviderLogo?: string | null;
							promotionalProviderWideLogo?: string | null;
							watchNowLabel?: string | null;
							backgroundImages?: Array<{
								__typename?: 'BackgroundImage';
								imageURL: string;
								size: Types.BackgroundImageSize;
							} | null> | null;
							externalTrackers: Array<{
								__typename?: 'ExternalTracker';
								type: Types.ExternalTrackerType;
								data: string;
							}>;
							promotionalVideo?: { __typename?: 'PromotionalVideo'; url: string } | null;
							watchNowOffer: {
								__typename?: 'Offer';
								id: string;
								standardWebURL?: string | null;
								streamUrl?: string | null;
								retailPrice?: string | null;
								retailPriceValue?: number | null;
								lastChangeRetailPriceValue?: number | null;
								currency?: string | null;
								presentationType: Types.PresentationType;
								monetizationType: Types.MonetizationType;
								availableTo?: any | null;
								package: {
									__typename?: 'Package';
									id: string;
									icon: string;
									packageId: number;
									clearName: string;
									shortName: string;
									technicalName: string;
									iconWide: string;
									hasRectangularIcon: boolean;
								};
							};
							nodeOverrides: Array<{
								__typename?: 'NodeOverride';
								nodeId: string;
								promotionalImageUrl?: string | null;
								watchNowOffer?: { __typename?: 'Offer'; standardWebURL?: string | null } | null;
							}>;
							node:
								| { __typename: 'Article'; nodeId: string }
								| { __typename: 'Author'; nodeId: string }
								| { __typename: 'Bundle'; nodeId: string }
								| { __typename: 'Device'; nodeId: string }
								| { __typename: 'Episode'; nodeId: string }
								| {
										__typename: 'GenericTitleList';
										id: string;
										type: Types.GenericTitleListType;
										nodeId: string;
										followedlistEntry?: {
											__typename?: 'FollowedListEntry';
											createdAt: any;
											name: string;
										} | null;
										content: {
											__typename?: 'GenericTitleListContent';
											name: string;
											visibility: Types.GenericTitleListVisibility;
										};
										titles: {
											__typename?: 'GenericTitleListConnection';
											totalCount: number;
											edges?: Array<{
												__typename?: 'GenericTitleListEdge';
												cursor: string;
												node:
													| {
															__typename?: 'Movie';
															id: string;
															objectId: number;
															objectType: Types.ObjectType;
															content: {
																__typename?: 'MovieContent';
																fullPath: string;
																posterUrl?: string | null;
																title: string;
																originalReleaseYear?: number | null;
																isReleased: boolean;
																scoring: {
																	__typename?: 'Scoring';
																	imdbVotes?: number | null;
																	imdbScore?: number | null;
																	tomatoMeter?: number | null;
																	certifiedFresh?: boolean | null;
																	jwRating?: number | null;
																};
															};
													  }
													| {
															__typename?: 'Season';
															id: string;
															objectId: number;
															objectType: Types.ObjectType;
															content: {
																__typename?: 'SeasonContent';
																fullPath: string;
																posterUrl?: string | null;
																title: string;
																originalReleaseYear?: number | null;
																isReleased: boolean;
																scoring: {
																	__typename?: 'Scoring';
																	imdbVotes?: number | null;
																	imdbScore?: number | null;
																	tomatoMeter?: number | null;
																	certifiedFresh?: boolean | null;
																	jwRating?: number | null;
																};
															};
													  }
													| {
															__typename?: 'Show';
															id: string;
															objectId: number;
															objectType: Types.ObjectType;
															content: {
																__typename?: 'ShowContent';
																fullPath: string;
																posterUrl?: string | null;
																title: string;
																originalReleaseYear?: number | null;
																isReleased: boolean;
																scoring: {
																	__typename?: 'Scoring';
																	imdbVotes?: number | null;
																	imdbScore?: number | null;
																	tomatoMeter?: number | null;
																	certifiedFresh?: boolean | null;
																	jwRating?: number | null;
																};
															};
													  };
											}> | null;
										};
								  }
								| { __typename: 'Genre'; nodeId: string }
								| {
										__typename: 'Movie';
										objectId: number;
										objectType: Types.ObjectType;
										nodeId: string;
										content: {
											__typename?: 'MovieContent';
											fullPath: string;
											posterUrl?: string | null;
											title: string;
											originalReleaseYear?: number | null;
											isReleased: boolean;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												jwRating?: number | null;
											};
											genres: Array<{
												__typename?: 'Genre';
												shortName: string;
												translation: string;
											}>;
											externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										offers: Array<{
											__typename?: 'Offer';
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											id: string;
											package: { __typename?: 'Package'; id: string; packageId: number };
										}>;
										watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
								  }
								| { __typename: 'MultiStepSportEvent'; nodeId: string }
								| { __typename: 'Offer'; nodeId: string }
								| { __typename: 'Package'; nodeId: string }
								| { __typename: 'Page'; nodeId: string }
								| { __typename: 'Person'; nodeId: string }
								| {
										__typename: 'Season';
										objectId: number;
										objectType: Types.ObjectType;
										nodeId: string;
										content: {
											__typename?: 'SeasonContent';
											fullPath: string;
											posterUrl?: string | null;
											title: string;
											originalReleaseYear?: number | null;
											isReleased: boolean;
											seasonNumber: number;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												jwRating?: number | null;
											};
											genres: Array<{
												__typename?: 'Genre';
												shortName: string;
												translation: string;
											}>;
											externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										offers: Array<{
											__typename?: 'Offer';
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											id: string;
											package: { __typename?: 'Package'; id: string; packageId: number };
										}>;
										show: {
											__typename: 'Show';
											id: string;
											objectId: number;
											objectType: Types.ObjectType;
											content: { __typename?: 'ShowContent'; originalTitle: string };
											watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										};
								  }
								| {
										__typename: 'Show';
										objectId: number;
										objectType: Types.ObjectType;
										nodeId: string;
										content: {
											__typename?: 'ShowContent';
											fullPath: string;
											posterUrl?: string | null;
											title: string;
											originalReleaseYear?: number | null;
											isReleased: boolean;
											scoring: {
												__typename?: 'Scoring';
												imdbScore?: number | null;
												jwRating?: number | null;
											};
											genres: Array<{
												__typename?: 'Genre';
												shortName: string;
												translation: string;
											}>;
											externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
										offers: Array<{
											__typename?: 'Offer';
											monetizationType: Types.MonetizationType;
											presentationType: Types.PresentationType;
											id: string;
											package: { __typename?: 'Package'; id: string; packageId: number };
										}>;
										watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
										seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number };
								  }
								| { __typename: 'SingleStepSportEvent'; nodeId: string }
								| { __typename: 'SportCompetitionV2'; nodeId: string }
								| { __typename: 'SportCompetitorV2'; nodeId: string }
								| { __typename: 'Url'; nodeId: string };
						} | null;
					} | null;
				};
				seenState: { __typename?: 'ShowSeenState'; progress: number; seenEpisodeCount: number };
				tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
				seasons: Array<{
					__typename?: 'Season';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					totalEpisodeCount: number;
					availableTo: Array<{
						__typename?: 'AvailableTo';
						availableToDate: any;
						availableCountDown: number;
						package: { __typename?: 'Package'; id: string; shortName: string };
					}>;
					content: {
						__typename?: 'SeasonContent';
						posterUrl?: string | null;
						seasonNumber: number;
						fullPath: string;
						title: string;
						isReleased: boolean;
						originalReleaseYear?: number | null;
						upcomingReleases: Array<{
							__typename?: 'UpcomingRelease';
							releaseDate?: any | null;
							releaseCountDown?: number | null;
						}>;
					};
					show: {
						__typename: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						content: { __typename?: 'ShowContent'; title: string };
					};
					fallBackClips: {
						__typename?: 'SeasonContent';
						clips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
							name: string;
						}>;
						videobusterClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
							name: string;
						}>;
						dailymotionClips: Array<{
							__typename?: 'Clip';
							sourceUrl: string;
							externalId: string;
							provider: Types.ClipProvider;
							name: string;
						}>;
					};
				}>;
				recentEpisodes: Array<{
					__typename?: 'Episode';
					id: string;
					objectId: number;
					objectType: Types.ObjectType;
					uniqueOfferCount: number;
					seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					flatrate: Array<{
						__typename?: 'Offer';
						id: string;
						package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
					}>;
					buy: Array<{
						__typename?: 'Offer';
						id: string;
						package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
					}>;
					rent: Array<{
						__typename?: 'Offer';
						id: string;
						package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
					}>;
					free: Array<{
						__typename?: 'Offer';
						id: string;
						package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
					}>;
					fast: Array<{
						__typename?: 'Offer';
						id: string;
						package: { __typename?: 'Package'; id: string; clearName: string; packageId: number };
					}>;
					content: {
						__typename?: 'EpisodeContent';
						title: string;
						shortDescription?: string | null;
						episodeNumber: number;
						seasonNumber: number;
						isReleased: boolean;
						runtime?: number | null;
						upcomingReleases: Array<{
							__typename?: 'UpcomingRelease';
							releaseDate?: any | null;
							label: Types.UpcomingReleaseLabel;
							package?: { __typename?: 'Package'; id: string; packageId: number } | null;
						}>;
					};
				}>;
		  }
		| { __typename: 'SingleStepSportEvent'; id: string }
		| { __typename: 'SportCompetitionV2'; id: string }
		| { __typename: 'SportCompetitorV2'; id: string }
		| {
				__typename: 'Url';
				metaDescription?: string | null;
				metaKeywords?: string | null;
				metaRobots?: string | null;
				metaTitle?: string | null;
				heading1?: string | null;
				heading2?: string | null;
				htmlContent?: any | null;
				id: string;
		  }
		| null;
};

export const GetNodeTitleDetailsDocument = gql`
	query GetNodeTitleDetails(
		$entityId: ID!
		$country: Country!
		$language: Language!
		$episodeMaxLimit: Int
		$platform: Platform! = WEB
		$allowSponsoredRecommendations: SponsoredRecommendationsInput
		$format: ImageFormat
		$backdropProfile: BackdropProfile
		$streamingChartsFilter: StreamingChartsFilter
	) {
		node(id: $entityId) {
			... on Url {
				metaDescription
				metaKeywords
				metaRobots
				metaTitle
				heading1
				heading2
				htmlContent
			}
			...TitleDetails
		}
	}
	${TitleDetailsFragmentDoc}
`;
