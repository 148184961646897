import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { TitleOfferFragmentDoc, FastOfferFragmentDoc } from '../../buybox/graphql/fragments/Offer.fragment';
export type GetBuyBoxOffersQueryVariables = Types.Exact<{
	nodeId: Types.Scalars['ID']['input'];
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	filterFlatrate: Types.OfferFilter;
	filterBuy: Types.OfferFilter;
	filterRent: Types.OfferFilter;
	filterFree: Types.OfferFilter;
	platform?: Types.Platform;
}>;

export type GetBuyBoxOffersQuery = {
	__typename?: 'Query';
	node?:
		| { __typename: 'Article'; id: string }
		| { __typename: 'Author'; id: string }
		| { __typename: 'Bundle'; id: string }
		| { __typename: 'Device'; id: string }
		| {
				__typename: 'Episode';
				offerCount: number;
				maxOfferUpdatedAt?: any | null;
				id: string;
				flatrate: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				buy: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				rent: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				free: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				fast: Array<{
					__typename?: 'Offer';
					availableTo?: any | null;
					availableFromTime?: any | null;
					availableToTime?: any | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				bundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						deeplinkRoku?: string | null;
						package: {
							__typename?: 'Package';
							id: string;
							technicalName: string;
							packageId: number;
							clearName: string;
							icon: string;
							hasRectangularIcon: boolean;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
		  }
		| { __typename: 'GenericTitleList'; id: string }
		| { __typename: 'Genre'; id: string }
		| {
				__typename: 'Movie';
				offerCount: number;
				maxOfferUpdatedAt?: any | null;
				id: string;
				flatrate: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				buy: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				rent: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				free: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				fast: Array<{
					__typename?: 'Offer';
					availableTo?: any | null;
					availableFromTime?: any | null;
					availableToTime?: any | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				bundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						deeplinkRoku?: string | null;
						package: {
							__typename?: 'Package';
							id: string;
							technicalName: string;
							packageId: number;
							clearName: string;
							icon: string;
							hasRectangularIcon: boolean;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
				promotedBundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						deeplinkRoku?: string | null;
						package: {
							__typename?: 'Package';
							id: string;
							technicalName: string;
							packageId: number;
							clearName: string;
							icon: string;
							hasRectangularIcon: boolean;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
				promotedOffers: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
		  }
		| { __typename: 'MultiStepSportEvent'; id: string }
		| { __typename: 'Offer'; id: string }
		| { __typename: 'Package'; id: string }
		| { __typename: 'Page'; id: string }
		| { __typename: 'Person'; id: string }
		| {
				__typename: 'Season';
				offerCount: number;
				maxOfferUpdatedAt?: any | null;
				id: string;
				flatrate: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				buy: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				rent: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				free: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				fast: Array<{
					__typename?: 'Offer';
					availableTo?: any | null;
					availableFromTime?: any | null;
					availableToTime?: any | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				bundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						deeplinkRoku?: string | null;
						package: {
							__typename?: 'Package';
							id: string;
							technicalName: string;
							packageId: number;
							clearName: string;
							icon: string;
							hasRectangularIcon: boolean;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
				promotedBundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						deeplinkRoku?: string | null;
						package: {
							__typename?: 'Package';
							id: string;
							technicalName: string;
							packageId: number;
							clearName: string;
							icon: string;
							hasRectangularIcon: boolean;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
				promotedOffers: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
		  }
		| {
				__typename: 'Show';
				offerCount: number;
				maxOfferUpdatedAt?: any | null;
				id: string;
				flatrate: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				buy: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				rent: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				free: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				fast: Array<{
					__typename?: 'Offer';
					availableTo?: any | null;
					availableFromTime?: any | null;
					availableToTime?: any | null;
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
				bundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						deeplinkRoku?: string | null;
						package: {
							__typename?: 'Package';
							id: string;
							technicalName: string;
							packageId: number;
							clearName: string;
							icon: string;
							hasRectangularIcon: boolean;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
				promotedBundles: Array<{
					__typename?: 'PromotedBundleEdge';
					promotionUrl: string;
					node: {
						__typename?: 'Bundle';
						id: string;
						clearName: string;
						icon: string;
						technicalName: string;
						bundleId: number;
						packages: Array<{
							__typename?: 'Package';
							icon: string;
							id: string;
							clearName: string;
							packageId: number;
						}>;
					};
					offer: {
						__typename?: 'Offer';
						id: string;
						presentationType: Types.PresentationType;
						monetizationType: Types.MonetizationType;
						retailPrice?: string | null;
						retailPriceValue?: number | null;
						currency?: string | null;
						lastChangeRetailPriceValue?: number | null;
						type: Types.OfferType;
						standardWebURL?: string | null;
						streamUrl?: string | null;
						elementCount?: number | null;
						availableTo?: any | null;
						subtitleLanguages: Array<any>;
						videoTechnology: Array<Types.VideoTechnology>;
						audioTechnology: Array<Types.AudioTechnology>;
						audioLanguages: Array<any>;
						deeplinkRoku?: string | null;
						package: {
							__typename?: 'Package';
							id: string;
							technicalName: string;
							packageId: number;
							clearName: string;
							icon: string;
							hasRectangularIcon: boolean;
							planOffers: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
								children: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
								}>;
							}>;
						};
					};
				}>;
				promotedOffers: Array<{
					__typename?: 'Offer';
					id: string;
					presentationType: Types.PresentationType;
					monetizationType: Types.MonetizationType;
					retailPrice?: string | null;
					retailPriceValue?: number | null;
					currency?: string | null;
					lastChangeRetailPriceValue?: number | null;
					type: Types.OfferType;
					standardWebURL?: string | null;
					streamUrl?: string | null;
					elementCount?: number | null;
					availableTo?: any | null;
					subtitleLanguages: Array<any>;
					videoTechnology: Array<Types.VideoTechnology>;
					audioTechnology: Array<Types.AudioTechnology>;
					audioLanguages: Array<any>;
					deeplinkRoku?: string | null;
					package: {
						__typename?: 'Package';
						id: string;
						packageId: number;
						clearName: string;
						technicalName: string;
						icon: string;
						hasRectangularIcon: boolean;
						planOffers: Array<{
							__typename?: 'PackagePlanOffer';
							title?: string | null;
							retailPrice?: string | null;
							isTrial?: boolean | null;
							durationDays?: number | null;
							retailPriceValue?: number | null;
							children: Array<{
								__typename?: 'PackagePlanOffer';
								title?: string | null;
								retailPrice?: string | null;
								isTrial?: boolean | null;
								durationDays?: number | null;
								retailPriceValue?: number | null;
							}>;
						}>;
					};
				}>;
		  }
		| { __typename: 'SingleStepSportEvent'; id: string }
		| { __typename: 'SportCompetitionV2'; id: string }
		| { __typename: 'SportCompetitorV2'; id: string }
		| { __typename: 'Url'; id: string }
		| null;
};

export const GetBuyBoxOffersDocument = gql`
	query GetBuyBoxOffers(
		$nodeId: ID!
		$country: Country!
		$language: Language!
		$filterFlatrate: OfferFilter!
		$filterBuy: OfferFilter!
		$filterRent: OfferFilter!
		$filterFree: OfferFilter!
		$platform: Platform! = WEB
	) {
		node(id: $nodeId) {
			id
			__typename
			... on MovieOrShowOrSeasonOrEpisode {
				offerCount(country: $country, platform: $platform)
				maxOfferUpdatedAt(country: $country, platform: $platform)
				flatrate: offers(country: $country, platform: $platform, filter: $filterFlatrate) {
					...TitleOffer
				}
				buy: offers(country: $country, platform: $platform, filter: $filterBuy) {
					...TitleOffer
				}
				rent: offers(country: $country, platform: $platform, filter: $filterRent) {
					...TitleOffer
				}
				free: offers(country: $country, platform: $platform, filter: $filterFree) {
					...TitleOffer
				}
				fast: offers(
					country: $country
					platform: $platform
					filter: { monetizationTypes: [FAST], bestOnly: true }
				) {
					...FastOffer
				}
				bundles(country: $country, platform: WEB) {
					node {
						id
						clearName
						icon(profile: S100)
						technicalName
						bundleId
						packages(country: $country, platform: $platform) {
							icon
							id
							clearName
							packageId
						}
					}
					promotionUrl
					offer {
						...TitleOffer
						package {
							id
							technicalName
						}
					}
				}
			}
			... on MovieOrShowOrSeason {
				promotedBundles(country: $country, platform: WEB) {
					node {
						id
						clearName
						icon(profile: S100)
						technicalName
						bundleId
						packages(country: $country, platform: $platform) {
							icon
							id
							clearName
							packageId
						}
					}
					promotionUrl
					offer {
						...TitleOffer
						package {
							id
							technicalName
						}
					}
				}
				promotedOffers(country: $country, platform: WEB, filter: { bestOnly: true }) {
					...TitleOffer
				}
			}
		}
	}
	${TitleOfferFragmentDoc}
	${FastOfferFragmentDoc}
`;
